<template>
  <div class="w100 h100 test">
    <div id="aliSlide"></div>
  </div>
</template>

<script>
export default {
  name: "test",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {
    // 实例化ic对象
    AWSC.use("ic", function(state, module) {
      // 初始化
      window.ic = module.init({
        // 应用类型标识。它和使用场景标识（scene字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
        appkey: "FFFF0N00000000009C35",
        // 使用场景标识。它和应用类型标识（appkey字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
        scene: "ic_message_h5",
        // 声明智能验证需要渲染的目标元素ID。
        renderTo: "aliSlide",
        // 该配置项为测试项 在仅用来测试验证码不同状态时使用。上线时请将其删除. 智能验证test配置项有4种不同的值对应不同的验证码状态，具体请参考文中参数定义说明部分。
        // test: module.TEST_PASS, // 测试智能验证通过
        // test: module.TEST_BLOCK, // 测试智能验证失败
        test: module.TEST_NC_PASS, // 唤醒滑动验证并验证通过
        // test: module.TEST_NC_BLOCK, // 唤醒滑动验证并验证失败
        // 验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
        success: function(data) {
          window.console && console.log(3333, data.sessionId);
          window.console && console.log(333, data.sig);
          window.console && console.log(3333, data.token);
        },
        // 验证失败时触发该回调参数
        fail: function(failCode) {
          console.log(222, failCode);
        },
        // 验证码加载异常时触发该回调参数
        error: function(errorCode) {
          console.log(111, errorCode);
        },
      });
    });
  },
};
</script>

<style lang="less" scoped></style>
